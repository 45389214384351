import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RootLayouts from "./Layouts/RootLayouts";
import Homepage from "./Pages/Homepage";
import Course from "./Components/Course";
import ContactUs from "./Components/ContactUs";
import CourseDetail from "./Components/CourseDetail";
import Error404 from "./Pages/Error404";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RootLayouts />}>
          <Route path="" element={<Homepage />} />
          <Route path="course" element={<Course />} />
          <Route path="contact" element={<ContactUs />} />
          <Route path="course/:type/:stack" element={<CourseDetail />} />
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
