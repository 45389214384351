import React from "react";

const WhyUs = () => {
  return (
    <div
      style={{
        backgroundImage: `url("https://cdn.staropstech.com/starops/TechElite/bg/secondrybg.webp")`,
        backgroundSize: "cover",
      }}
    >
      <div className="container mx-auto p-2 md:py-20 flex flex-col items-center justify-center gap-5">
        <p className="text-xl text-primary font-medium">Core Features</p>
        <h1 className="text-5xl font-bold  text-center">
          Why
          <span className="text-secondry">&nbsp; Choose &nbsp;</span>
          TechElite
        </h1>

        <div className="flex flex-row flex-wrap mt-10 items-center justify-center gap-10 w-full">
          <div className="flex-1 min-w-60   group   p-6  bg-white hover:bg-secondry hover:-translate-y-2 transition-all duration-500 ease-in shadow-sm rounded-lg flex flex-col items-start justify-center gap-5">
            <div className="h-16 aspect-square bg-white rounded-full">
              <img
                className="w-full"
                src="https://cdn.staropstech.com/starops/TechElite/icons/techelite stacks.png"
                alt="techelite"
              />
            </div>
            <p className="text-2xl  font-bold group-hover:text-white transition-all duration-300 ease-in">
              Engage in Real Projects
            </p>
            <p className="text-lg  font-medium text-gray-500 group-hover:text-white transition-all duration-300 ease-in">
              Apply your skills through hands-on experience with live projects,
              gaining practical insights and enhancing your expertise.
            </p>
          </div>
          <div className="flex-1 group min-w-60   p-6  bg-white hover:bg-secondry hover:-translate-y-2 transition-all duration-300 ease-in shadow-sm rounded-lg flex flex-col items-start justify-center gap-5">
            <div className="h-16 aspect-square bg-white rounded-full">
              <img
                src="https://cdn.staropstech.com/starops/TechElite/icons/techelite learning.png"
                alt="techelite"
              />
            </div>
            <p className="text-2xl  font-bold group-hover:text-white transition-all duration-300 ease-in">
              Expert Instructor
            </p>
            <p className="text-lg  font-medium text-gray-500 group-hover:text-white transition-all duration-300 ease-in">
              Receive guidance and insights from seasoned professionals,
              ensuring a rich learning experience with an expert instructor.
            </p>
          </div>
          <div className="flex-1 group min-w-60  p-6  bg-white hover:bg-secondry hover:-translate-y-2 transition-all duration-300 ease-in shadow-sm rounded-lg flex flex-col items-start justify-center gap-5">
            <div className="h-16 aspect-square bg-white rounded-full">
              <img
                src="https://cdn.staropstech.com/starops/TechElite/icons/techelite support.png"
                alt="techelite"
              />
            </div>
            <p className="text-2xl  font-bold group-hover:text-white transition-all duration-300 ease-in">
              <span className="tracking-widest">1:1 </span>Strong Support
            </p>
            <p className="text-lg  font-medium text-gray-500 group-hover:text-white transition-all duration-300 ease-in">
              Get round-the-clock assistance and reliable support, ensuring
              you're never alone on your learning journey with our{" "}
              <span className="tracking-widest">1:1</span> Strong Support.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
