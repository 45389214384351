import React from "react";
import logo from "../Assets/logo.png";

const Footer = () => {
  return (
    <div
      style={{
        backgroundImage: `url(https://cdn.staropstech.com/starops/TechElite/bg/advantagebg.webp)`,
        backgroundSize: "cover",
      }}
      className="w-full"
    >
      <div className="container p-2 md:py-10   mx-auto  font-thin grid grid-cols-2  md:grid-cols-4 gap-5 sm:gap-14">
        <div className="flex flex-col col-span-2 md:col-span-1 text-lg font-normal leading-6 gap-1">
          <img src={logo} alt="starops logo" width="120" />
          <p className="text-left ">
            At StarOpstechElite, we empower tech enthusiasts to unlock their
            full potential through expert training in cutting-edge technologies.
          </p>
          <p className="leading-5 mt-1 text-gray-500 ">
            Ph:
            <a rel="noreferrer" target="_blank" href={`tel:+916394735818`}>
              +91 6394735818
            </a>
          </p>
          <p className="leading-5  text-gray-500">
            Email:
            <a
              target="_blank"
              rel="noreferrer"
              href={`mailto:techelite@staropstech.com`}
            >
              techelite@staropstech.com
            </a>
          </p>
          <div className="flex flex-row justify-start gap-4 items-center mt-4">
            <a
              href="https://www.linkedin.com/company/starops-techelite/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="h-10 white-icon aspect-square hover:scale-110 transition-all duration-300 ease-in-out"
                src="https://cdn-icons-png.flaticon.com/128/3536/3536505.png"
                alt="starOps Techelite Linkedin"
              />
            </a>
            <a
              href="https://www.instagram.com/starops.techelite/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                className="h-10 white-icon aspect-square hover:scale-110 transition-all duration-300 ease-in-out"
                src="https://cdn-icons-png.flaticon.com/128/2111/2111463.png"
                alt="starOps Techelite instagram"
              />
            </a>
            <a
              href="https://x.com/staroptechelite"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="h-9  white-icon aspect-square hover:scale-110 transition-all duration-300 ease-in-out"
                src="https://cdn-icons-png.flaticon.com/128/5968/5968830.png"
                alt="starOps Techelite twitter"
              />
            </a>
          </div>
        </div>
        <div className="col-span-2 md:col-span-1">
          <iframe
            title="staropstech address"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14026.540398126008!2d77.49010353017052!3d28.490533920553517!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cea5df6ca01fd%3A0x78a54533cd2ed38!2sGamma%20II%2C%20Greater%20Noida%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1709106202029!5m2!1sen!2sin"
            className="rounded-lg w-full h-full "
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div>
          <h1 className="text-xl font-semibold mb-2 ">Company</h1>
          <ul>
            <li>
              <a href="/contact"> Enquiry</a>
            </li>

            <li>
              <a href="https://staropstech.com/blog" target="_blank">
                Blog
              </a>
            </li>
            <li>
              <a href="/">Terms & Conditions</a>
            </li>
          </ul>
        </div>
        <div>
          <h1 className="text-xl font-semibold mb-2 ">Courses</h1>
          <ul>
            <li>
              <a href="/course/rapid/devops">DevOps Rapid</a>
            </li>
            <li>
              <a href="/course/rapid/python">Python Rapid</a>
            </li>
            <li>
              <a href="/course/rapid/react">React Rapid</a>
            </li>
            <li>
              <a href="/course/Phoenix/devops">DevOps Phoenix</a>
            </li>
            <li>
              <a href="/course/Phoenix/python">Python Phoenix</a>
            </li>
            <li>
              <a href="/course/Phoenix/react">React Phoenix</a>
            </li>
          </ul>
        </div>
      </div>
      <p className="w-full text-center font-medium text-gray-500">
        Copyright © 2023 StarOps Technologies Pvt. Ltd.
      </p>
    </div>
  );
};

export default Footer;
