import React from "react";

const Error404 = () => {
  return (
    <div
      style={{
        backgroundImage: `url("https://cdn.staropstech.com/starops/TechElite/bg/404.webp")`,
        backgroundSize: "cover",
      }}
      className="min-h-44 md:min-h-[90vh] flex flex-col items-center justify-end pb-10"
    >
      <a
        className="bg-secondry min-w-36 text-white p-3 hover:ring-2 hover:ring-offset-2  hover:ring-secondry transition-all ease-in-out duration-700  text-center rounded-lg  cursor-pointer"
        href="/"
      >
        Return Home
      </a>
    </div>
  );
};

export default Error404;
