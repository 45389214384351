import React, { useEffect, useRef, useState } from "react";
import FAQ from "../Common/FAQ";
import CourseContentList from "../Common/CourseContentList";
import data from "../Data/course.json";
import { useParams } from "react-router-dom";
import EnrollNow from "./EnrollNow";

const CourseDetail = () => {
  const [isActive, setIsactive] = useState(false);
  const params = useParams();
  console.log(params);
  const [coursedata] = data[params.type].filter(
    (item) => item.title === params.stack
  );
  const [currentDiv, setCurrentDiv] = useState(1);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const divs = [ref1, ref2, ref3, ref4].filter(
        (ref) => ref.current !== null
      );
      let currentScroll = window.scrollY - 100;
      let index = divs.findIndex(
        (ref) => ref.current.offsetTop > currentScroll
      );
      setCurrentDiv(index !== -1 ? index + 1 : 4);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [ref1, ref2, ref3, ref4]);

  const scrollToRef = (ref) => {
    if (ref.current) {
      const scrollOffset = ref.current.offsetTop - 120;
      window.scrollTo({
        top: scrollOffset,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      {isActive && (
        <EnrollNow
          onClose={() => setIsactive(false)}
          course={coursedata.name}
        />
      )}
      <div className="w-full bg-white">
        <div className="container mx-auto flex flex-row flex-wrap  pt-6">
          <div className="w-full md:w-4/6 p-6 flex flex-col items-start justify-start gap-4">
            <img
              className="w-full aspect-video object-cover rounded-md"
              src={coursedata.coverurl}
              alt="Techelite StarOps courses"
            />
            <h1 className="text-4xl font-semibold ">{coursedata.name}</h1>
            <p className="text-lg  leading-6 text-gray-500">
              {coursedata.small_desc}
            </p>
            <div className="sticky top-[87px] sm:top-[74px] z-20  bg-white w-full flex flex-row flex-wrap gap-2 md:gap-0">
              <button
                onClick={() => {
                  setCurrentDiv(1);
                  scrollToRef(ref1);
                }}
                className={`${
                  currentDiv === 1
                    ? " bg-secondry text-white"
                    : "text-gray-500 bg-gray-100"
                } flex-1 p-3 min-w-40  text-lg transition-all duration-300 ease-in-out lg:rounded-l-lg`}
              >
                Overview
              </button>
              <button
                onClick={() => {
                  setCurrentDiv(2);
                  scrollToRef(ref2);
                }}
                className={`${
                  currentDiv === 2
                    ? " bg-secondry text-white"
                    : "text-gray-500 bg-gray-100"
                } flex-1 p-3 min-w-40  text-lg transition-all duration-300 ease-in-out`}
              >
                Prerequisites
              </button>
              <button
                onClick={() => {
                  setCurrentDiv(3);
                  scrollToRef(ref3);
                }}
                className={`${
                  currentDiv === 3
                    ? " bg-secondry text-white"
                    : "text-gray-500 bg-gray-100"
                } flex-1 p-3 min-w-40  text-lg transition-all duration-300 ease-in-out`}
              >
                Course Content
              </button>
              <button
                onClick={() => {
                  setCurrentDiv(4);
                  scrollToRef(ref4);
                }}
                className={`${
                  currentDiv === 4
                    ? " bg-secondry text-white"
                    : "text-gray-500 bg-gray-100"
                } flex-1 p-3 min-w-40  text-lg transition-all duration-300 ease-in-out lg:rounded-r-lg`}
              >
                FAQ
              </button>
            </div>

            <div className=" flex flex-col gap-6 py-4" ref={ref1}>
              <h1 className="text-2xl font-semibold ">Course Description</h1>
              <div className="text-lg  leading-6 text-gray-500">
                {coursedata.description
                  .split("\n\n")
                  .map((paragraph, index) => (
                    <div key={index}>
                      <p>{paragraph}</p>
                      <br />
                    </div>
                  ))}
              </div>
              <div className="bg-gray-100 rounded-md p-6 flex flex-col items-start justify-start gap-4">
                <h1 className="text-2xl font-semibold ">What you will Learn</h1>
                <div className="flex flex-row  flex-wrap gap-2">
                  {coursedata.whatyouwilllearn.map((item, key) => {
                    return (
                      <div
                        key={key}
                        className="flex flex-row gap-3 items-start justify-start flex-1 md:min-w-[35%] "
                      >
                        <svg
                          className="h-8  min-w-8"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM5 5V19H19V5H5ZM11.003 16L6.76 11.757L8.174 10.343L11.003 13.172L16.659 7.515L18.074 8.929L11.003 16Z"
                            fill="#7B7B8A"
                          />
                        </svg>
                        <p className="text-lg min-w-48  leading-6 text-gray-500">
                          {item}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className=" flex flex-col gap-6 py-2" ref={ref2}>
              <h1 className="text-2xl font-semibold ">Prerequisites</h1>
              <div className="flex flex-col  flex-wrap gap-2">
                {coursedata.prerequisit.map((item, key) => {
                  return (
                    <div
                      key={key}
                      className="flex flex-row gap-3 items-start justify-start flex-1 w-full "
                    >
                      <svg
                        className="h-8  min-w-8"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM5 5V19H19V5H5ZM11.003 16L6.76 11.757L8.174 10.343L11.003 13.172L16.659 7.515L18.074 8.929L11.003 16Z"
                          fill="#7B7B8A"
                        />
                      </svg>
                      <p className="text-lg  leading-6 text-gray-500">{item}</p>
                    </div>
                  );
                })}
              </div>
            </div>

            <h1 className="text-2xl font-semibold  " ref={ref3}>
              Course Content
            </h1>
            <CourseContentList data={coursedata.coursecontent} />
            <div className="bg-gray-100 rounded-md p-6 flex flex-col items-start justify-start gap-4">
              <h1 className="text-2xl font-semibold ">
                Who this course is for ?
              </h1>
              <div className="flex flex-row  flex-wrap gap-2">
                {coursedata.courseisfor.map((item, key) => {
                  return (
                    <div
                      key={key}
                      className="flex flex-row gap-3 items-start justify-start flex-1 md:min-w-[35%] "
                    >
                      <svg
                        className="h-8  min-w-8"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM5 5V19H19V5H5ZM11.003 16L6.76 11.757L8.174 10.343L11.003 13.172L16.659 7.515L18.074 8.929L11.003 16Z"
                          fill="#7B7B8A"
                        />
                      </svg>
                      <p className="text-lg min-w-48  leading-6 text-gray-500">
                        {item}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <h1 className="text-2xl font-semibold " ref={ref4}>
              Frequntly Asked Questions
            </h1>
            <FAQ data={coursedata.FAQ} />
          </div>

          <div className="w-full md:w-2/6 p-6 min-w-80">
            <div className="sticky top-20 border p-6 bg-white rounded-md shadow-lg flex flex-col items-start justify-start gap-4">
              <img
                className="w-full aspect-video object-cover rounded-md"
                src={coursedata.morecontent.albumurl}
                alt=""
              />
              <button
                onClick={() => setIsactive(true)}
                className="bg-secondry w-full  p-4 leading-4 text-white  text-center  font-bold rounded-lg hover:ring-2 hover:ring-offset-2 transition-all ease-in-out duration-700  hover:ring-secondry "
              >
                Enroll Now
              </button>
              <div className="w-full flex flex-col">
                {params.type === "rapid" && (
                  <div className="flex flex-row text-xl font-medium justify-between items-center  border-b-2 py-2 ">
                    <p>Price </p>
                    <p className="text-gray-500">
                      &#8377;
                      <span className="line-through decoration-red-600">
                        {" "}
                        4999
                      </span>
                      &nbsp;
                      <span>3499</span>
                    </p>
                  </div>
                )}
                <div className="flex flex-row text-xl font-medium justify-between items-center  border-b-2 py-2 ">
                  <p>Certification </p>
                  <p className="text-gray-500">
                    {coursedata.morecontent.certification}
                  </p>
                </div>
                <div className="flex flex-row text-xl font-medium justify-between items-center  border-b-2 py-2 ">
                  <p>Live Classes </p>
                  <p className="text-gray-500">
                    {coursedata.morecontent.liveclass}
                  </p>
                </div>
                <div className="flex flex-row text-xl font-medium justify-between items-center  border-b-2 py-2 ">
                  <p>Assignments </p>
                  <p className="text-gray-500">
                    {coursedata.morecontent.assignments}
                  </p>
                </div>
                <div className="flex flex-row text-xl font-medium justify-between items-center  border-b-2 py-2 ">
                  <p>Projects </p>
                  <p className="text-gray-500">
                    {coursedata.morecontent.assignments}
                  </p>
                </div>
                {params.type === "Phoenix" && (
                  <div className="flex flex-row text-xl font-medium justify-between items-center  border-b-2 py-2 ">
                    <p>Mock Interview </p>
                    <p className="text-gray-500">
                      {coursedata.morecontent.assignments}
                    </p>
                  </div>
                )}
                <div className="flex flex-row text-xl font-medium justify-between items-center  border-b-2 py-2 ">
                  <p>Duration </p>
                  <p className="text-gray-500">
                    {coursedata.morecontent.duration}
                  </p>
                </div>
                <div className="flex flex-row text-xl font-medium justify-between items-center   py-2 ">
                  <p>Language</p>
                  <p className="text-gray-500">
                    {coursedata.morecontent.language}
                  </p>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetail;
