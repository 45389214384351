import React from "react";

const Count = () => {
  return (
    <div className="container mx-auto p-2 sm:py-10 flex flex-col items-center justify-center">
      <p className="text-xl text-primary font-medium mb-4">Why Trust Us </p>
      <h1 className="text-5xl font-bold  text-center">
        TechElite In
        <span className="text-secondry">&nbsp; Figures &nbsp;</span>
      </h1>
      <div className="py-10 w-full flex flex-row flex-wrap items-center justify-items-center justify-center sm:justify-between">
        <div className="group flex flex-col items-center justify-center p-5">
          <h1 className="text-primary text-6xl font-bold group-hover:drop-shadow-lg group-hover:scale-105 transition-all ease-in-out duration-500">
            500+
          </h1>
          <p className="text-xl font-medium text-gray-500 group-hover:text-black transition-all ease-in-out duration-500 group-hover:scale-105  ">
            Enrolled Students
          </p>
        </div>
        <div className="group flex flex-col items-center justify-center p-5">
          <h1 className="text-primary text-6xl font-bold group-hover:drop-shadow-lg group-hover:scale-105 transition-all ease-in-out duration-500 ">
            5+
          </h1>
          <p className="text-xl font-medium text-gray-500 group-hover:text-black transition-all ease-in-out duration-500 group-hover:scale-105 ">
            Courses
          </p>
        </div>
        <div className="group flex flex-col items-center justify-center p-5">
          <h1 className="text-primary text-6xl font-bold  group-hover:drop-shadow-lg group-hover:scale-105 transition-all ease-in-out duration-500 ">
            80+
          </h1>
          <p className="text-xl font-medium text-gray-500 group-hover:text-black transition-all ease-in-out duration-500 group-hover:scale-105 ">
            Course Topics
          </p>
        </div>
        <div className="group flex flex-col items-center justify-center p-5">
          <h1 className="text-primary text-6xl font-bold group-hover:drop-shadow-lg group-hover:scale-105 transition-all ease-in-out duration-500  ">
            100+
          </h1>
          <p className="text-xl font-medium text-gray-500 group-hover:text-black transition-all ease-in-out duration-500 group-hover:scale-105 ">
            Certified Students
          </p>
        </div>
      </div>
    </div>
  );
};

export default Count;
