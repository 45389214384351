import React, { useState } from "react";
import logo from "../Assets/logo.png";
const Navbar = () => {
  const [isMenueOpen, setIsMenueOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenueOpen((prev) => !prev);
  };

  return (
    <div className="w-full fixed top-0  z-50 shadow-lg py-1 backdrop-blur-3xl bg-white/60 ">
      <div className="w-full container mx-auto flex flex-row justify-between items-center flex-wrap">
        <div className="p-2 sm:p-0 w-full sm:w-1/5 flex items-center justify-between">
          <a href="/">
            <img src={logo} alt="Techelite logo" className="h-16 p-2" />
          </a>
          <button
            onClick={toggleMenu}
            className="w-10 aspect-square sm:hidden "
          >
            {isMenueOpen ? (
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            )}
          </button>
        </div>
        {/* big screen */}
        <div
          className={`hidden sm:flex w-4/5 text-lg font-medium text-center justify-end items-center gap-8`}
        >
          <a
            className=" hover:text-primary transition-all ease-in-out duration-700"
            href="/"
          >
            Home
          </a>
          <a
            className=" hover:text-primary transition-all ease-in-out duration-700"
            href="/course"
          >
            Courses
          </a>

          <a
            href="/contact"
            className="bg-secondry min-w-36 text-white px-2 py-1 hover:ring-2 hover:ring-offset-2  hover:ring-secondry transition-all ease-in-out duration-700  text-center rounded-lg  cursor-pointer"
          >
            Join Now
          </a>
        </div>
        {/* small screen */}
        <div
          className={`grid w-full mb-2 grid-cols-1 sm:hidden text-lg font-medium   text-center ${
            isMenueOpen ? "sm:grid" : "hidden"
          } `}
        >
          <a
            className=" hover:text-primary transition-all ease-in-out duration-700"
            href="/"
          >
            Home
          </a>
          <a
            className=" hover:text-primary transition-all ease-in-out duration-700"
            href="/course"
          >
            Courses
          </a>
          <a
            href="/contact"
            className="bg-secondry mx-4 text-white px-2 py-1 hover:ring-2 hover:ring-offset-2  hover:ring-secondry transition-all ease-in-out duration-700  text-center rounded-lg  cursor-pointer"
          >
            Join Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
