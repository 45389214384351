import React from "react";

const About = () => {
  return (
    <div className="bg-light-bg">
      <div className="container p-2 sm:py-10 mx-auto flex flex-col sm:flex-row items-center justify-center gap-5 sm:gap-10 ">
        <div className="w-full sm:w-3/7">
          <img
            className="w-full"
            src="https://cdn.staropstech.com/starops/TechElite/bg/about.webp"
            alt="About StarOps Techelite"
          />
        </div>

        <div className="w-full sm:w-3/7 flex flex-col  gap-5">
          <p className="text-xl text-primary font-medium">About Starops</p>
          <h1 className="text-5xl font-bold  ">
            The Place Where You Can
            <span className="text-secondry"> Achieve</span>
          </h1>
          <p className="font-medium text-xl text-gray-500">
            Welcome to StarOps TechElite, unlocking tech's boundless potential
            for your future success.
          </p>
          <div className="flex flex-row items-center justify-start gap-5">
            <img
              className="h-16 aspect-square"
              src="https://cdn.staropstech.com/starops/TechElite/icons/techelite target.png"
              alt="techElite target"
            />
            <div>
              <h1 className=" text-lg font-bold">Our Mission</h1>
              <p>
                Empowering individuals with accessible and comprehensive tech
                education to achieve their goals.
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center justify-start gap-5">
            <img
              className="h-16 aspect-square"
              src="https://cdn.staropstech.com/starops/TechElite/icons/techelite vision.png"
              alt="Techelite Vision"
            />
            <div>
              <h1 className=" text-lg font-bold">Our Vision</h1>
              <p>
                Revolutionizing the landscape of tech education, empowering
                individuals to thrive in a digital world.
              </p>
            </div>
          </div>
          {/* <button className="bg-secondry w-max  mt-5 p-4 leading-4 text-white  font-medium rounded-lg hover:ring-2 hover:ring-offset-2 transition-all ease-in-out duration-700  hover:ring-secondry ">
            Learn More
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default About;
