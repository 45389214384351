import React, { useEffect, useState } from "react";
import About from "../Components/About";
import Count from "../Components/Count";
import WhyUs from "../Components/WhyUs";
import Course from "../Components/Course";
import Advantages from "../Components/Advantages";
import ContactUs from "../Components/ContactUs";
import { toast } from "react-toastify";
import { SuscribeNewsLetterUrl } from "../Utils/properties";
import "react-toastify/dist/ReactToastify.css";

const Homepage = () => {
  const [email1, setEmail1] = useState("");
  const [email2, setEmail2] = useState("");
  const [error1, setError1] = useState(false);
  const [error2, setError2] = useState(false);

  function isValidEmail(email) {
    if (!email) {
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleSubmit = async (email, setError, setEmail) => {
    if (isValidEmail(email)) {
      setError(false);
      const formdata = new FormData();
      formdata.append("Email", email);
      const toastId = toast.loading("Subscribing for Newsletter...");

      try {
        const response = await fetch(SuscribeNewsLetterUrl, {
          method: "POST",
          body: formdata,
        });
        const data = await response.json();

        if (data.status === 200) {
          setEmail("");

          toast.update(toastId, {
            render: "Subscribed successfully",
            type: "success",
            isLoading: false,
            autoClose: 5000,
          });
        } else {
          toast.update(toastId, {
            render: "Something went wrong",
            type: "error",
            isLoading: false,
            autoClose: 5000,
          });
        }
      } catch (error) {
        toast.update(toastId, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      }
    } else {
      setError(true);
    }
  };

  const HandleSubmit1 = (e) => {
    e.preventDefault();
    handleSubmit(email1, setError1, setEmail1);
  };

  const HandleSubmit2 = (e) => {
    e.preventDefault();
    handleSubmit(email2, setError2, setEmail2);
  };

  return (
    <>
      <div
        style={{
          backgroundImage: `url("https://cdn.staropstech.com/starops/TechElite/bg/mainbg.webp")`,
          backgroundSize: "cover",
        }}
      >
        <div className="container px-2 sm:px-0 mx-auto py-20  flex flex-col md:flex-row items-center  justify-between">
          <div className="w-full md:w-1/2">
            <div className="text-5xl sm:text-7xl font-bold text-left  flex flex-col">
              <p>StarOps TechElite</p>

              <p className="text-4xl sm:text-6xl ">
                <span className="text-primary ">Code Bright, </span>
                <br />
                Future in Sight!
              </p>
            </div>
            <p className="text-lg sm:text-2xl text-gray-500 pt-10">
              Dive into immersive structured coding courses with hands-on
              experience.
            </p>
            <div
              className={`${
                error1 ? "border border-red-500" : ""
              } bg-white  mt-5 rounded-md text-lg px-2 sm:px-4 py-2 flex flex-row items-center justify-between`}
            >
              <input
                className=" w-full outline-none text-gray-500"
                type="text"
                name="email"
                onChange={(e) => {
                  setError1(false);
                  setEmail1(e.target.value);
                }}
                value={email1}
                placeholder="Enter your Email"
              />
              <button
                onClick={HandleSubmit1}
                className="bg-secondry p-3 px-5 leading-4 text-white  font-medium rounded-lg hover:ring-2 hover:ring-offset-2 transition-all ease-in-out duration-700  hover:ring-secondry "
              >
                Get Started Now
              </button>
            </div>
          </div>
          <img
            className="w-full md:w-1/2 md:scale-[1.2]"
            loading="lazy"
            src="https://cdn.staropstech.com/starops/TechElite/bg/studentimg.png"
            alt="StarOps Techelite Student"
          />
        </div>
      </div>
      <About />
      <Count />
      <WhyUs />
      <Course />
      <Advantages />
      <div className="bg-primary py-24 bg-opacity-80 ">
        <div className="mx-auto container p-2 flex flex-col sm:flex-row items-end  justify-between gap-5">
          <div className="w-full sm:w-1/2 flex flex-row items-center justify-start gap-5 sm:gap-10 ">
            <svg
              width={74}
              height={74}
              viewBox="0 0 74 74"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_152_486)">
                <path
                  d="M73.1197 26.4356L65.1838 20.5794V10.8446C65.1838 9.64526 64.2141 8.6756 63.0148 8.6756H49.2866L38.3014 0.433533C37.5359 -0.140605 36.4769 -0.140605 35.6986 0.433533L24.7134 8.6756H10.9852C9.78586 8.6756 8.81621 9.64526 8.81621 10.8446V20.5922L0.880345 26.4484C0.331724 26.8439 0 27.4946 0 28.1835V71.8308C0 73.0301 0.969655 73.9997 2.16897 73.9997H71.831C73.0303 73.9997 74 73.0301 74 71.8308V28.1835C74 27.4946 73.6683 26.8439 73.1197 26.4356ZM69.6621 29.2808V35.5197L65.1838 37.7525V25.9763L69.6621 29.2808ZM37 4.87353L42.0524 8.66284H31.9348L37 4.87353ZM60.8459 13.0135V39.9087L37 51.7742L13.1541 39.8959V13.0135H60.8459ZM8.81621 25.9763V37.7525L4.33793 35.507V29.268L8.81621 25.9763ZM4.33793 69.6618V40.3553L36.0303 56.1377C36.6428 56.4439 37.3572 56.4439 37.9569 56.1377L69.6621 40.3553V69.6618H4.33793Z"
                  fill="white"
                />
                <path
                  d="M23.9893 26.0147H50.0169C51.2162 26.0147 52.1858 25.045 52.1858 23.8457C52.1858 22.6464 51.2162 21.6768 50.0169 21.6768H23.9893C22.79 21.6768 21.8203 22.6464 21.8203 23.8457C21.8331 25.045 22.8027 26.0147 23.9893 26.0147Z"
                  fill="white"
                />
                <path
                  d="M23.9893 34.6905H37.0031C38.2024 34.6905 39.172 33.7208 39.172 32.5215C39.172 31.3222 38.2024 30.3525 37.0031 30.3525H23.9893C22.79 30.3525 21.8203 31.3222 21.8203 32.5215C21.8203 33.7208 22.8027 34.6905 23.9893 34.6905Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_152_486">
                  <rect width={74} height={74} fill="white" />
                </clipPath>
              </defs>
            </svg>

            <p className="text-3xl font-bold w-64 text-white">
              Subscribe to My Newsletter
            </p>
          </div>
          <form className="form w-full sm:w-1/2 flex flex-row items-center justify-between gap-8">
            <input
              name="Email"
              onChange={(e) => {
                setError2(false);
                setEmail2(e.target.value);
              }}
              value={email2}
              placeholder="Enter your Email"
              className={`${
                error2 ? "border-b-2 border-red-500" : "border-b-2 border-white"
              } w-9/12 p-1 text-opacity-90 bg-transparent  outline-none text-white text-xl placeholder-white`}
              type="text"
            />
            <button
              onClick={HandleSubmit2}
              className="bg-white py-4 w-3/12 leading-4 text-primary font-medium rounded-lg  text-xl hover:ring-2 hover:ring-offset-2 transition-all ease-in-out duration-700  hover:ring-white "
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <ContactUs />
    </>
  );
};

export default Homepage;
