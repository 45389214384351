import React, { useEffect, useState } from "react";
import Navbar from "../Common/Navbar";
import Footer from "../Common/Footer";
import { Outlet } from "react-router-dom";
import OfferWindow from "../Common/OfferWindow";

const RootLayouts = () => {
  const [isOfferVisible, setIsOfferVisible] = useState(true);

  useEffect(() => {
    const lastOfferCloseTime = localStorage.getItem("lastOfferCloseTime");
    if (lastOfferCloseTime) {
      const currentTime = new Date().getTime();
      if (currentTime - parseInt(lastOfferCloseTime) < 30 * 60 * 1000) {
        setIsOfferVisible(false);
      }
    }
  }, []);

  const handleCloseOffer = () => {
    setIsOfferVisible(false);
    localStorage.setItem("lastOfferCloseTime", new Date().getTime().toString());
  };

  return (
    <div>
      <Navbar />
      <div className="mt-16 pt-2">
        {isOfferVisible && <OfferWindow onClose={handleCloseOffer} />}
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default RootLayouts;
