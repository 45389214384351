import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import { ValidateForm } from "../Utils/Helper";
import { EnrollUrl, SendEmailUrl } from "../Utils/properties";

const EnrollNow = ({ onClose, course }) => {
  const modalRef = useRef();
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };
  const handleInteractionInsideModal = (event) => {
    event.stopPropagation();
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const initiialformdata = {
    Name: "",
    Email: "",
    Phone: "",
    Course: "",
    Address: "",
  };
  const initiialerror = {
    Name: "",
    Email: "",
    Phone: "",
    Course: "",
    Address: "",
  };
  const [formdata, setFormdata] = useState(initiialformdata);
  const [formError, setFormError] = useState(initiialerror);
  const HandleChange = (e) => {
    const { name, value } = e.target;
    setFormError({ ...formError, [name]: false });
    setFormdata({ ...formdata, [name]: value });
  };

  const SendEmail = async () => {
    const mydata = {
      name: formdata.Name,
      email: formdata.Email,
      mobile_no: formdata.Phone,
      address: formdata.Address,
      course: course,
    };
    const response = await fetch(SendEmailUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(mydata),
    });
    const data = await response.json();
    console.log(data);
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    const validation = ValidateForm(formdata);
    if (validation.valid) {
      const formData = new FormData();
      formData.append("Name", formdata.Name);
      formData.append("Course", course);
      formData.append("Email", formdata.Email);
      formData.append("Phone", formdata.Phone);
      formData.append("Address", formdata.Address);
      const toastId = toast.loading("Hold Back!");
      try {
        const response = await fetch(EnrollUrl, {
          method: "POST",
          body: formData,
        });
        const data = await response.json();
        if (data.status === 200) {
          SendEmail();
          toast.update(toastId, {
            render: "Thanks for Enrolling! We'll reach out soon.",
            type: "success",
            isLoading: false,
            autoClose: 5000,
          });
          setFormdata(initiialformdata);
          onClose();
        } else {
          toast.update(toastId, {
            render: "Something went wrong",
            type: "error",
            isLoading: false,
            autoClose: 5000,
          });
        }
      } catch (error) {
        toast.update(toastId, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      }
    } else {
      setFormError({ ...formError, ...validation.errorin });
    }
  };

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0  flex items-center justify-center bg-black bg-opacity-60  z-40"
      onClick={onClose}
    >
      <div
        className="w-[90%] sm:w-[55%] max-w-96 md:1/2  bg-white rounded-lg shadow-lg  lg:w-1/2"
        onClick={handleInteractionInsideModal}
        ref={modalRef}
      >
        <form className="w-full border grid grid-cols-2 p-6  gap-1 gap-x-2 rounded-lg shadow-md">
          <div className="col-span-2 ">
            <label
              htmlFor="Name"
              className={`${formError.Name === true ? "text-red-500" : ""}`}
            >
              Name <span className="text-xs text-red-500">*</span>
            </label>
            <input
              className="w-full text-lg p-4 bg-gray-100 rounded-md outline-none text-gray-500 font-medium"
              type="text"
              name="Name"
              value={formdata.Name}
              onChange={HandleChange}
              placeholder="Name"
              required
            />
          </div>

          <div className="col-span-2 ">
            <label
              htmlFor="Email"
              className={`${formError.Email === true ? "text-red-500" : ""}`}
            >
              Email <span className="text-xs text-red-500">*</span>
            </label>

            <input
              className="w-full text-lg p-4 bg-gray-100 rounded-md outline-none text-gray-500 font-medium"
              type="text"
              name="Email"
              required
              value={formdata.Email}
              onChange={HandleChange}
              placeholder="Email"
            />
          </div>
          <div className="col-span-2  ">
            <label
              htmlFor="Phone"
              className={`${formError.Phone === true ? "text-red-500" : ""}`}
            >
              Phone <span className="text-xs text-red-500">*</span>
            </label>
            <input
              className="w-full text-lg p-4 bg-gray-100 rounded-md outline-none text-gray-500 font-medium"
              type="text"
              name="Phone"
              required
              value={formdata.Phone}
              onChange={HandleChange}
              placeholder="Phone"
            />
          </div>

          <div className=" col-span-2 ">
            <label htmlFor="Name">Address</label>
            <input
              className="w-full  text-lg p-4 bg-gray-100 rounded-md outline-none text-gray-500 font-medium"
              type="text"
              name="Address"
              value={formdata.Address}
              onChange={HandleChange}
              placeholder="Address"
            />
          </div>

          <div className="col-span-2 mt-5 mx-auto">
            <button
              onClick={HandleSubmit}
              className="bg-secondry min-w-48 p-4 leading-4 text-white  font-medium rounded-lg hover:ring-2 hover:ring-offset-2 transition-all ease-in-out duration-700  hover:ring-secondry "
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default EnrollNow;
