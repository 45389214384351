import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ValidateForm } from "../Utils/Helper";
import { ContactUrl } from "../Utils/properties";

const ContactUs = () => {
  const params = useParams();
  const [formdata, setFormdata] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Course: "",
    Message: "",
  });

  const [formError, setFormError] = useState({
    Name: false,
    Email: false,
    Phone: false,
    Course: false,
    Message: false,
  });
  useEffect(() => {
    if (Object.keys(params).length !== 0) {
      setFormdata({ ...formdata, Course: params.course });
    }
  }, []);

  const HandleChange = (e) => {
    const { name, value } = e.target;
    setFormError({ ...formError, [name]: false });
    setFormdata({ ...formdata, [name]: value });
  };

  const HandleSubmit = async (e) => {
    e.preventDefault();
    const validation = ValidateForm(formdata);

    if (validation.valid) {
      const formData = new FormData();
      formData.append("Name", formdata.Name);
      formData.append("Course", formdata.Course);
      formData.append("Email", formdata.Email);
      formData.append("Phone", formdata.Phone);
      formData.append("Message", formdata.Message);
      const toastId = toast.loading("Hold Back!");
      try {
        const response = await fetch(ContactUrl, {
          method: "POST",
          body: formData,
        });
        console.log(response);
        const data = await response.json();

        if (data.status === 200) {
          setFormdata({
            Name: "",
            Email: "",
            Phone: "",
            Course: "",
            Message: "",
          });

          toast.update(toastId, {
            render: "Thank you for Enquiry! We'll be in touch soon.",
            type: "success",
            isLoading: false,
            autoClose: 5000,
          });
        } else {
          toast.update(toastId, {
            render: "Something went wrong",
            type: "error",
            isLoading: false,
            autoClose: 5000,
          });
        }
      } catch (error) {
        toast.update(toastId, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
      }
    } else {
      setFormError({ ...formError, ...validation.errorin });
    }
  };
  return (
    <div>
      <div className="container min-h-[90vh] p-2 sm:py-10 mx-auto flex flex-col sm:flex-row items-center justify-center gap-10 sm:gap-10 ">
        <div className="w-full sm:w-3/7 flex flex-col  gap-5 ">
          <p className="text-xl text-primary font-medium">Contact Us</p>
          <h1 className="text-5xl font-bold  ">
            Get In Touch
            <span className="text-secondry"> Today </span>
          </h1>
          <p className="font-medium text-xl text-gray-500">
            We're here to assist you with any inquiries or support you need.
          </p>
          <div className=" flex flex-col items-start justify-start gap-5">
            <div className="flex flex-row items-center justify-start gap-5">
              <img
                className="h-14 aspect-square"
                src="https://cdn.staropstech.com/starops/TechElite/icons/mail.png"
                alt="starops techelite mail"
              />
              <div>
                <p className="text-lg sm:text-xl font-bold">Email-Us :</p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`mailto:techelite@staropstech.com`}
                  className="text-base sm:text-lg font-medium text-gray-500"
                >
                  techelite@staropstech.com
                </a>
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-5">
              <img
                className="h-14 aspect-square"
                src="https://cdn.staropstech.com/starops/TechElite/icons/phone.png"
                alt="starops phone"
              />
              <div>
                <h1 className="text-lg sm:text-xl font-bold">Call Us:</h1>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`tel:+916394735818`}
                  className="text-base sm:text-lg font-medium text-gray-500"
                >
                  +91 6394735818
                </a>
              </div>
            </div>
            <div className="flex flex-row items-center justify-start gap-5">
              <img
                className="h-14 aspect-square"
                src="https://cdn.staropstech.com/starops/TechElite/icons/office.png"
                alt="starops techelite address"
              />
              <div>
                <h1 className="text-lg sm:text-xl font-bold">Office :</h1>
                <p className="text-base sm:text-lg font-medium text-gray-500">
                  Gamma 2, Greater Noida , Uttar Pradesh
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-3/7 ">
          <form className="w-full border grid grid-cols-2 p-6  gap-1 gap-x-2 rounded-lg shadow-md">
            <div className="col-span-2 sm:col-span-1">
              <label
                htmlFor="Name"
                className={`${formError.Name === true ? "text-red-500" : ""}`}
              >
                Name <span className="text-xs text-red-500">*</span>
              </label>
              <input
                className="w-full text-lg p-4 bg-gray-100 rounded-md outline-none text-gray-500 font-medium"
                type="text"
                name="Name"
                value={formdata.Name}
                onChange={HandleChange}
                placeholder="Name"
                required
              />
            </div>
            <div className=" col-span-2 sm:col-span-1">
              <label htmlFor="Name">Course</label>
              <input
                className="w-full  text-lg p-4 bg-gray-100 rounded-md outline-none text-gray-500 font-medium"
                type="text"
                name="Course"
                value={formdata.Course}
                onChange={HandleChange}
                placeholder="Course"
              />
            </div>
            <div className="col-span-2 sm:col-span-1 ">
              <label
                htmlFor="Email"
                className={`${formError.Email === true ? "text-red-500" : ""}`}
              >
                Email <span className="text-xs text-red-500">*</span>
              </label>

              <input
                className="w-full text-lg p-4 bg-gray-100 rounded-md outline-none text-gray-500 font-medium"
                type="text"
                name="Email"
                required
                value={formdata.Email}
                onChange={HandleChange}
                placeholder="Email"
              />
            </div>
            <div className="col-span-2 sm:col-span-1 ">
              <label
                htmlFor="Phone"
                className={`${formError.Phone === true ? "text-red-500" : ""}`}
              >
                Phone <span className="text-xs text-red-500">*</span>
              </label>
              <input
                className="w-full text-lg p-4 bg-gray-100 rounded-md outline-none text-gray-500 font-medium"
                type="text"
                name="Phone"
                required
                value={formdata.Phone}
                onChange={HandleChange}
                placeholder="Phone"
              />
            </div>
            <div className="col-span-2 ">
              <label htmlFor="Name">Message</label>
              <textarea
                rows="4"
                name="Message"
                value={formdata.Message}
                onChange={HandleChange}
                placeholder="Enter Your message"
                className="w-full text-lg p-4 bg-gray-100 rounded-md outline-none text-gray-500 font-medium"
              />
            </div>
            <div className="col-span-2  mx-auto">
              <button
                onClick={HandleSubmit}
                className="bg-secondry min-w-48 p-4 leading-4 text-white  font-medium rounded-lg hover:ring-2 hover:ring-offset-2 transition-all ease-in-out duration-700  hover:ring-secondry "
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
