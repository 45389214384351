import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";

const OfferWindow = ({ onClose }) => {
  const navigate = useNavigate();
  const modalRef = useRef();
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };
  const handleInteractionInsideModal = (event) => {
    event.stopPropagation();
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);
  return ReactDOM.createPortal(
    <div
      className="fixed inset-0  flex items-center justify-center bg-black bg-opacity-60  z-40"
      onClick={onClose}
    >
      <div
        className="w-[90%] sm:w-[55%] max-w-96 md:1/2  bg-white rounded-lg shadow-lg  lg:w-1/2 relative"
        onClick={handleInteractionInsideModal}
        ref={modalRef}
      >
        <img
          onClick={onClose}
          className="absolute h-5 aspect-square right-0 top-0"
          src="https://cdn-icons-png.flaticon.com/128/1617/1617543.png"
          alt="cross"
        />
        <img
          onClick={() => {
            navigate("/course");
          }}
          className="rounded-sm"
          src="https://cdn.staropstech.com/starops/TechElite/bg/offer.png"
          alt="statops techelite offer"
        />
      </div>
    </div>,
    document.getElementById("portal")
  );
};

export default OfferWindow;
