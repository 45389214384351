export function ValidateForm(form) {
  let errors = {};
  let valid = true;

  // Validate Name
  if (!form.Name || !/^[a-zA-Z\s]+$/.test(form.Name)) {
    errors.Name = true;
    valid = false;
  }

  // Validate Email
  if (!form.Email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(form.Email)) {
    errors.Email = true;
    valid = false;
  }

  // Validate Phone
  if (!form.Phone || !/^\d{10}$/.test(form.Phone)) {
    errors.Phone = true;
    valid = false;
  }

  return valid ? { valid: true } : { valid: false, errorin: errors };
}

// Example usage:
