import React from "react";
import data from "../Data/course.json";

const Course = () => {
  return (
    <div className="mt-5">
      <div className="container mx-auto p-2  md:py-10 flex flex-col items-center justify-center gap-5">
        <p className="text-xl text-primary font-medium">Rapid Courses</p>
        <h1 className="text-5xl font-bold  text-center">
          Our
          <span className="text-secondry">&nbsp; Rapid &nbsp;</span>
          Courses
        </h1>
        <div className="flex flex-row flex-wrap mt-10 items-center justify-center gap-10 w-full">
          {data.rapid.map((item, key) => {
            return (
              <a
                href={`/course/rapid/${item.title}`}
                key={key}
                className="flex-1 min-w-72 max-w-96 group bg-white  hover:-translate-y-2 transition-all duration-300 ease-in rounded-lg flex flex-col items-start justify-center  shadow-md"
              >
                <div className="w-full relative">
                  <img
                    className="w-full rounded-t-lg"
                    src={item.coverurl}
                    alt="Rapid courses by Techelite"
                  />
                  <div className="absolute top-4 left-4  py-1 px-2 rounded-md font-bold  text-lg bg-white bg-opacity-45 group-hover:bg-opacity-70 transition-all duration-500 ease-out">
                    6 Weeks
                  </div>
                </div>
                <div className="w-full flex flex-col items-start p-4 ">
                  <p className="text-2xl  font-bold group-hover:text-secondry transition-all duration-300 ease-in">
                    {item.name}
                  </p>
                  <p className="text-sm  font-bold py-1   group-hover:text-primary transition-all duration-300 ease-in">
                    Certification
                  </p>
                  <p className="text-lg  font-medium text-gray-500 mt-1 ">
                    {item.small_desc}
                  </p>
                  <div className="w-full flex flex-col sm:flex-row mt-1  justify-between text-gray-500 group-hover:text-black transition-all duration-300 ease-in">
                    <div className="flex  flex-row items-center justify-start ">
                      <img
                        className="h-8 aspect-square"
                        src="https://cdn.staropstech.com/starops/TechElite/icons/time.png"
                        alt="clock icon"
                      />
                      <p className="text-lg  font-medium ">{item.duration}</p>
                    </div>
                    <div className="flex  flex-row items-center justify-start">
                      <img
                        className="h-8 aspect-square"
                        src="https://cdn.staropstech.com/starops/TechElite/icons/project.png"
                        alt="project icon"
                      />
                      <p className="text-lg  font-medium ">{item.project}</p>
                    </div>
                  </div>
                  <button className="bg-secondry mt-3 h-full min-w-36 w-full text-white px-2 py-1 hover:ring-2 hover:ring-offset-2  hover:ring-secondry transition-all ease-in-out duration-700  text-center rounded-lg  cursor-pointer">
                    Enroll Now
                  </button>
                </div>
              </a>
            );
          })}
        </div>
      </div>

      <div className="container mx-auto p-2 md:py-10 flex flex-col items-center justify-center gap-5">
        <p className="text-xl text-primary font-medium">Phoenix Courses</p>
        <h1 className="text-5xl font-bold  text-center">
          Our
          <span className="text-secondry">&nbsp; Phoenix &nbsp;</span>
          Courses
        </h1>

        <div className="flex flex-row flex-wrap mt-10 items-center justify-center gap-10 w-full">
          {data.Phoenix.map((item, key) => {
            return (
              <a
                href={`/course/Phoenix/${item.title}`}
                key={key}
                className="flex-1 min-w-72 max-w-96 group bg-white  hover:-translate-y-2 transition-all duration-300 ease-in rounded-lg flex flex-col items-start justify-center  shadow-md"
              >
                <div className="w-full relative">
                  <img
                    className="w-full rounded-t-lg"
                    src={item.coverurl}
                    alt="Phoenix courses by techelite"
                  />
                  <div className="absolute top-4 left-4  py-1 px-2 rounded-md font-bold  text-lg bg-white bg-opacity-45 group-hover:bg-opacity-70 transition-all duration-500 ease-out">
                    24 Weeks
                  </div>
                </div>
                <div className="w-full flex flex-col items-start p-4 ">
                  <p className="text-2xl  font-bold group-hover:text-secondry transition-all duration-300 ease-in">
                    {item.name}
                  </p>
                  <p className="text-sm  font-bold py-1  group-hover:text-primary transition-all duration-300 ease-in">
                    Certification + Placement Offer
                    <span className="text-xs text-red-500"> *</span>
                  </p>
                  <p className="text-lg text-left  font-medium text-gray-500 mt-1">
                    {item.small_desc}
                  </p>

                  <div className="w-full flex flex-col sm:flex-row mt-1  justify-between text-gray-500 group-hover:text-black transition-all duration-300 ease-in">
                    <div className="flex  flex-row items-center justify-start ">
                      <img
                        className="h-8 aspect-square"
                        src="https://cdn.staropstech.com/starops/TechElite/icons/time.png"
                        alt="clock icon"
                      />
                      <p className="text-lg  font-medium ">{item.duration}</p>
                    </div>
                    <div className="flex  flex-row items-center justify-start">
                      <img
                        className="h-8 aspect-square"
                        src="https://cdn.staropstech.com/starops/TechElite/icons/project.png"
                        alt="project icon"
                      />
                      <p className="text-lg  font-medium ">{item.project}</p>
                    </div>
                  </div>
                  <button className="bg-secondry mt-3 h-full min-w-36 w-full text-white px-2 py-1 hover:ring-2 hover:ring-offset-2  hover:ring-secondry transition-all ease-in-out duration-700  text-center rounded-lg  cursor-pointer">
                    Enroll Now
                  </button>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Course;
