import React from "react";

const Advantages = () => {
  return (
    <div
      style={{
        backgroundImage: `url("https://cdn.staropstech.com/starops/TechElite/bg/advantagebg.webp")`,
        backgroundSize: "cover",
      }}
    >
      <div className="container p-2 sm:py-10 mx-auto flex flex-col sm:flex-row items-center justify-center gap-5 sm:gap-10 ">
        <div className="w-full sm:w-3/7 flex flex-col  gap-5">
          <p className="text-xl text-primary font-medium">
            Best Online Learning Platform
          </p>
          <h1 className="text-5xl font-bold  ">
            One Platfrom & Many
            <span className="text-secondry"> Advantages </span>
            For You
          </h1>
          <p className="font-medium text-xl text-gray-500">
            Discover the ultimate learning experience with our comprehensive and
            interactive platform, designed to help you achieve your goals.
          </p>
          <div className=" flex flex-col items-start justify-start gap-2">
            {[
              "Live Classes",
              "Certifications",
              "1:1 doubt support",
              "Structured Courses",
              "Mock Interview",
              "Work on live Projects",
              "Daily Assignments",
            ].map((item, key) => {
              return (
                <div
                  key={key}
                  className="flex flex-row items-center justify-start gap-2"
                >
                  <img
                    className="h-5 aspect-square"
                    src="https://cdn.staropstech.com/starops/TechElite/icons/tick.png"
                    alt="tick"
                  />
                  <h1 className="text-lg font-bold">{item}</h1>
                </div>
              );
            })}
          </div>
          <div className="h-10"></div>
          {/* <button className="bg-secondry w-max  mt-5 p-4 leading-4 text-white  font-medium rounded-lg hover:ring-2 hover:ring-offset-2 transition-all ease-in-out duration-700  hover:ring-secondry ">
            Learn More
          </button> */}
        </div>

        <div className="w-full sm:w-3/7">
          <img
            className="w-full"
            src="https://cdn.staropstech.com/starops/TechElite/bg/advantage.webp"
            alt="StarOps Techelite Advantages"
          />
        </div>
      </div>
    </div>
  );
};

export default Advantages;
