import React, { useState } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";

const CourseContentList = ({ data }) => {
  const [accordionData, setAccordionData] = useState(data);
  const toggleAccordion = (index) => {
    setAccordionData((prevData) =>
      prevData.map((item, i) => ({
        ...item,
        isOpen: i === index && !item.isOpen,
      }))
    );
  };
  const [animationParent] = useAutoAnimate();
  return (
    <div className="border w-full rounded-lg shadow-lg">
      {accordionData.map((item, index) => (
        <div ref={animationParent} key={index} className="flex flex-col">
          <p
            onClick={() => toggleAccordion(index)}
            className="flex justify-start items-center gap-2 cursor-pointer text-xl p-2 font-medium"
          >
            {item.isOpen ? (
              <img
                src="https://cdn.staropstech.com/starops/assets/extra-icons/minus.png"
                alt="minus-icon"
                className="h-5 aspect-square"
              />
            ) : (
              <img
                src="https://cdn.staropstech.com/starops/assets/extra-icons/plus.png"
                alt="plus-icon"
                className="h-5 aspect-square"
              />
            )}
            <span>{item.heading}</span>
          </p>

          {item.isOpen && (
            <div className="border-t text-gray-500 text-lg font-medium px-8 py-2 ">
              <ul>
                {item.topics.map((item, key) => {
                  return (
                    <li
                      key={key}
                      className="flex flex-row items-center justify-start gap-1"
                    >
                      <svg
                        className="max-w-4"
                        viewBox="-8.47 0 88.9 88.9"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          id="Group_18"
                          data-name="Group 18"
                          transform="translate(-652 -672)"
                        >
                          <path
                            id="Path_37"
                            data-name="Path 37"
                            d="M718.8,717.1V691.8L702.4,674H664.5A10.5,10.5,0,0,0,654,684.5v63.9a10.5,10.5,0,0,0,10.5,10.5h43.8a10.5,10.5,0,0,0,10.5-10.5v-8"
                            fill="none"
                            stroke="#ff7d83"
                            strokeLinecap="round"
                            strokeMiterlimit={10}
                            strokeWidth={4}
                          />
                          <path
                            id="Path_38"
                            data-name="Path 38"
                            d="M697.3,675.1v16.4s-.2,5.8,3.5,5.8h16.4"
                            fill="none"
                            stroke="#ff7d83"
                            strokeLinecap="round"
                            strokeMiterlimit={10}
                            strokeWidth={4}
                          />
                          <path
                            id="Path_39"
                            data-name="Path 39"
                            d="M721.9,729.2a3.172,3.172,0,0,1-3.7,3.7,3.209,3.209,0,0,1-2.5-2.5,3.172,3.172,0,0,1,3.7-3.7A3.512,3.512,0,0,1,721.9,729.2Z"
                            fill="#ff7d83"
                            stroke="#ff7d83"
                            strokeMiterlimit={10}
                            strokeWidth={4}
                          />
                        </g>
                      </svg>
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {index !== accordionData.length - 1 ? <hr /> : ""}
        </div>
      ))}
    </div>
  );
};

export default CourseContentList;
